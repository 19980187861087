
import { Component, Vue } from "vue-property-decorator";
import { closeWebView } from "@/service/libs/index";
import { Toast } from "vant";

@Component({
  name: "Perfectfail",
  components: {
    // HelloWorld,
    // Field,
  },
})
export default class Perfectfail extends Vue {
  private async handleBuy() {
    closeWebView();
  }
  private orderNo: any = localStorage.getItem("telegraphic-orderId");
  private orderInfo: any = {};
  private copyIcon = require("@/assets/copy.jpeg");
  private firstCopySuccess() {
    Toast(this.$t("copysuccess"));
  }
  private copy(value: string) {
    this.$copyText(value).then(
      () => {
        Toast(this.$t("copysuccess"));
      },
      () => {
        Toast(this.$t("copyfail"));
      }
    );
  }
  mounted() {
    let orderInfo = localStorage.getItem("telegraphic-info");
    if (orderInfo) {
      this.orderInfo = JSON.parse(orderInfo);
    }
  }
}
